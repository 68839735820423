@import url('https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lexend Deca', sans-serif;
}

.signin-form{
  width: 20rem;
  margin: 10rem auto;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid #dadce0;
}

.signin-form h1{
  margin-bottom: 2rem;
}

.action-buttons{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a{
  color: #17bf63;
  font-size: 0.84rem;
}

button{
  background: #17bf63;
  padding: 0.75rem 1rem;
  border: 0;
  border-radius: 5px;
  width: 6.5rem;
  cursor: pointer;
  font-size: 0.84rem;
  color: #fff;
  display: flex;
  justify-content: center;
}

button:hover{
  background: #15ac59;
}

input, textarea{
  margin-bottom: 1.5rem;
  border: 1px solid #dadce0;
  border-radius: 5px;
  padding: 1rem;
  outline: 0;
  width: 100%;
  font-size: 1rem;
}

.loading{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-right: 2px solid transparent;
  margin-right: 4px;

  animation: spin 750ms linear infinite;
}

.error{
  color: tomato;
  margin-bottom: 0.75rem;
  font-size: 0.84rem;
}

@keyframes spin{ to{transform: rotateZ(360deg)} }

.dashboard{
  display: grid;
  height: 100vh;
  grid-template-rows: 1fr 15fr;
  grid-template-columns: 1fr 5fr;
  color: #333;
}

.dashboard .sidebar{
  border-right: 2px solid #eee;
  grid-row: span 2;
}

.dashboard .sidebar a{
  display: block;
  padding: 1rem 2rem;
  text-decoration: none;
  color: #333;
}

.dashboard .sidebar a:hover{
  background: #eee;
}

.dashboard .header{
  border-bottom: 2px solid #eee;
  padding: 1rem;
  font-size: 20px;
}

.dashboard .content{
  padding: 2rem;
}

.dashboard .content h1{
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
}

.dashboard .content article{
  background: #f9f9f9;
  margin: 2rem 0;
  padding: 1rem 2rem;
  border-radius: 5px;
  box-shadow: 0px 2px 10px #ddd;
}

.dashboard .content article p{
  margin: 1rem 0;
  color: #444;
}

.dashboard .content article .buttons button{
  display: inline-block;
  margin: 1rem 0;
  margin-right: 1rem;
}

.buttons .missed{
  background: #f50b2a;
}

.buttons .missed:hover{
  background: #e90e2b;
}

.buttons .delayed{
  background: #f5b70b;
}

.buttons .delayed:hover{
  background: #e9ae0b;
}

.menuicon-wrapper{
  display: none;
}

.upper-profile{
  display: flex;
  align-items: center;
}

.upper-profile img{
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.upper-profile h1{
  border: 0 !important;
  padding-left: 1rem;
}

textarea{
  resize: none;
  height: 8rem;
  margin-top: 2rem;
}

.settings *{
  margin-top: 1rem;
}

.settings input{
  width: 15rem;
}

details{
  display: inline-block;
  position: relative;
}

details, summary{
  margin-top: 0 !important;
}

details input{
  position: absolute;
  width: 10rem;
  right: 0;
  box-shadow: 0 2px 10px #bdbdbd;
  z-index: 10;
}

summary{
  margin-left: 1rem;
  color: #17bf63;
  cursor: pointer;
  border-bottom: 2px solid #17bf63;
}

summary::-webkit-details-marker{
  display: none;
}

:focus{
  outline: 2px solid #17bf63;
}

.notification{
  position: absolute;
  top: -3rem;
  right: 2rem;
  padding: 1rem;
  margin: 0;
  box-shadow: 0 0 10px #dadada;
  border-radius: 5px;
  animation: notification 5s forwards;
}

@keyframes notification{
  10%{
    top: 2rem;
  }
  90%{
    top: 2rem;
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

.success{
  background: #17bf63;
  color: #fff;
}

.failure{
  background: #f50b2a;
  color: #fff;
}

.warning{
  background: #f5b70b;
}

.with-label{
  position: relative;
}

.with-label label{
  position: absolute;
  left: 10px;
  top: -10px;
  background: #fff;
  padding: 0 5px;
  color: #aaa;
}

input:focus + label::after{
  content: 'Save';
  position: absolute;
  top: 1.5rem;
  left: 15rem;
  color: #17bf63;
  cursor: pointer;
  border-bottom: 2px solid #17bf63;
}

@media (max-width: 700px){
  .dashboard{
    grid-template-columns: 1fr;
  }
  .dashboard .sidebar{
    transform: translateX(-100%);
    position: fixed;
    background: #fff;
    width: 75vw;
    box-shadow: 0 2px 10px #888;
    height: 100vh;
    transition: 300ms;
  }
  .dashboard .sidebar.open{
    transform: translateX(0);
  }
  .dashboard .content article .buttons button{
    margin: 0.25rem 0;
    width: 100%;
  }
  .menuicon-wrapper{
    display: block;
    position: absolute;
    right: 15px;
  }
}
